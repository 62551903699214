import { formatSentryError } from './telemetryHelpers';
import { captureException } from '@sentry/nextjs';
import {
    PAYMENT_TIER_ACCESS_LEVEL_NAMES,
    PAYWALLED_FEATURES,
    PLAN_ACCESS_LEVEL,
    PLAN_VALUE,
    REQUIRED_PLAN_FOR_PREMIUM_FEATURES
} from '../utils/constants/featureFlagConstants';
import axios from 'axios';
import {
    AuthContextInterface,
    FeatureFlagContextStateType,
    FeatureFlagsContextInterface,
    GlobalNarrationContextInterface,
    SubscriptionInterface
} from '../types/types';

export const checkFeatureIsPaywalled = (feature: keyof typeof REQUIRED_PLAN_FOR_PREMIUM_FEATURES, userAccessTierNumber: number) => {
    try {
        const featureRequiredAccessTierNumber = REQUIRED_PLAN_FOR_PREMIUM_FEATURES[feature];

        // * Check that user has a high enough plan to access the feature
        if (userAccessTierNumber < featureRequiredAccessTierNumber) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        const { message, cause } = formatSentryError('Error getting feature flag for plan requirement', 'getPremiumFeatureFlag', error);
        captureException(new Error(message, cause));

        // * default premium features as being enabled if check fails so that paying users never lose features. We'd rather give free access to a feature than take it away from a paying user
        return true;
    }
};

export const getAllFeaturePaywalls = (userAccessTierNumber: number): Record<PAYWALLED_FEATURES, boolean> => {
    const allFeatures = Object.keys(REQUIRED_PLAN_FOR_PREMIUM_FEATURES);
    const allFeaturePaywalls: Record<string, boolean> = {};

    allFeatures.forEach((feature) => {
        allFeaturePaywalls[feature] = checkFeatureIsPaywalled(feature as keyof typeof REQUIRED_PLAN_FOR_PREMIUM_FEATURES, userAccessTierNumber);
    });

    return allFeaturePaywalls;
};

export const getAllFeatureFlagsServerSide = async (distinctId: string): Promise<Record<string, string | boolean> | undefined> => {
    try {
        const response = await axios.get(`/api/v2/posthog/get-feature-flags/${distinctId}`);
        const featureFlagValue = response.data as Record<string, string | boolean>;
        return featureFlagValue;
    } catch (error) {
        const { message, cause } = formatSentryError('Error getting feature flag from Next API service side call', 'getFeatureFlagServerSide', error);
        captureException(new Error(message, cause));

        return undefined;
    }
};

export const getPlanTierName = (paymentTierAccessLevel: number | undefined): string => {
    if (paymentTierAccessLevel === undefined) {
        return '';
    }
    const tierName = PAYMENT_TIER_ACCESS_LEVEL_NAMES[paymentTierAccessLevel];

    if (tierName.toLowerCase().includes('tutorial')) {
        return 'Tutorial';
    }

    // * capitalize the first letter of each word
    const words = tierName.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    const nameCapitalized = capitalizedWords.join(' ');
    return nameCapitalized;
};

export const getSubscriptionAccessLevel = (
    subscription: SubscriptionInterface | null,
    bypassPaymentPortal: FeatureFlagsContextInterface['bypassPaymentPortal'],
    unlockEnterpriseFeatures: FeatureFlagsContextInterface['unlockEnterpriseFeatures'],
    tutorialParams: {
        isNewUser: boolean | undefined;
        enableFirstUseTutorialFlow: FeatureFlagContextStateType;
        hasCreatedAudio: boolean;
        hasVisitedPlansAndPricing: boolean;
        hasCreatedIntegration: boolean;
    }
) => {
    const { isNewUser, hasCreatedAudio, enableFirstUseTutorialFlow, hasVisitedPlansAndPricing, hasCreatedIntegration } = tutorialParams;

    const shouldCheckFirstUseCases = enableFirstUseTutorialFlow && isNewUser;

    let accessLevel = undefined;
    if (bypassPaymentPortal || unlockEnterpriseFeatures) {
        accessLevel = PLAN_ACCESS_LEVEL['ENTERPRISE'];
    } else if (subscription && subscription?.tier in PLAN_VALUE) {
        accessLevel = PLAN_ACCESS_LEVEL[subscription.tier];
    } else if (shouldCheckFirstUseCases && !hasCreatedAudio && !hasVisitedPlansAndPricing && !hasCreatedIntegration) {
        accessLevel = PLAN_ACCESS_LEVEL['TUTORIAL'];
    } else {
        accessLevel = PLAN_ACCESS_LEVEL['FREE'];
    }
    return accessLevel;
};

/**
 *
 * If the user is already in the tutorial, do not allow resuming/restarting the tutorial
 * If the successNarrations are still loading, do not allow resuming the tutorial
 * If there are no success narrations and all of the above is not true, allow resuming the tutorial
 * @param isFeaturePaywalled
 * @param successNarrations
 * @returns
 */
export const allowResumeTutorial = (
    isFeaturePaywalled: FeatureFlagsContextInterface['isFeaturePaywalled'],
    successNarrations: GlobalNarrationContextInterface['successNarrations'],
    rssIntegrationSettings: AuthContextInterface['rssIntegrationSettings'],
    hubspotIntegrationSettings: AuthContextInterface['hubspotIntegrationSettings']
) => {
    // * explicitly check for false and not undefined because undefined is a valid value but means that it is still loading
    return (
        isFeaturePaywalled.RESUME_TUTORIAL === false &&
        successNarrations !== undefined &&
        !successNarrations.length &&
        rssIntegrationSettings !== undefined &&
        !rssIntegrationSettings.length &&
        hubspotIntegrationSettings !== undefined &&
        !hubspotIntegrationSettings.length
    );
};
