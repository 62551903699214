import { useState } from 'react';
import { useFeatureFlagsContext } from '../../context/hooks/FeatureFlagsHook';
import { VStack, FormLabel, Input, Button, Text } from '@chakra-ui/react';
import { useAuth } from '../../context/AuthContext';

export const AdminUidSelector = () => {
    const { setCoreWorkspaceDocuments, user, group, project } = useAuth();
    const { isAdaurisAdmin } = useFeatureFlagsContext();
    const [adminSelectedUid, setAdminSelectedUid] = useState('');

    const handleClick = () => {
        setCoreWorkspaceDocuments(adminSelectedUid);
    };

    if (!isAdaurisAdmin) {
        return null;
    } else {
        return (
            <VStack data-testid="adminUidSelector">
                <FormLabel>Admin Toggle UID</FormLabel>
                <Input value={adminSelectedUid} onChange={(e) => setAdminSelectedUid(e.currentTarget.value)} />
                <Button onClick={handleClick}>Login</Button>
                {user?.uid !== group?.adminUser && (
                    <>
                        <Text color="red">THIS IS NOT YOUR WORKSPACE</Text>
                        <Text>Group ID: {group?.id}</Text>
                        <Text>Project ID: {project?.id}</Text>
                    </>
                )}
            </VStack>
        );
    }
};
