import { PAYWALLED_FEATURES } from './featureFlagConstants';

// TODO - these aren't current displayed anymore but we'll probably need to reference them in the future to move customers out of these plans
export const FEATURES_BASIC_LEGACY = [
    { featureName: '25 audio files/month' }, // TODO - add paywalled feature that aligns with new payments system
    { featureName: 'Intros and outros', paywalledFeature: PAYWALLED_FEATURES.INTROS_AND_OUTROS },
    { featureName: 'RSS feed importer' }, // TODO - move to free tier
    { featureName: 'Embed player', paywalledFeature: PAYWALLED_FEATURES.EMBED_PLAYER },
    { featureName: 'Podcast distribution', paywalledFeature: PAYWALLED_FEATURES.PODCAST_DISTRIBUTION },
    { featureName: 'Youtube distribution', paywalledFeature: PAYWALLED_FEATURES.YOUTUBE_DISTRIBUTION },
    { featureName: 'Player customization', paywalledFeature: PAYWALLED_FEATURES.PLAYER_CUSTOMIZATION },
    {
        featureName: 'Lexicon',
        paywalledFeature: PAYWALLED_FEATURES.LEXICON,
        tooltipText: 'Adjust and save the correct pronunciation of specific words'
    },
    { featureName: 'Basic support' },
    { featureName: 'Onboarding session (30 minutes)' },
    { featureName: 'Basic analytics', paywalledFeature: PAYWALLED_FEATURES.BASIC_ANALYTICS }
];

export const FEATURES_PRO_LEGACY = [
    { featureName: 'Everything in “Basic”' },
    { featureName: '250 audio files/month' }, // TODO - add paywalled feature that aligns with new payments system
    { featureName: 'Pro support' },
    { featureName: 'Onboarding session (1 hour)' },
    { featureName: 'Pro analytics', paywalledFeature: PAYWALLED_FEATURES.PRO_ANALYTICS }
];

export const FEATURES_BASIC = [
    { featureName: '60 credits per month' },
    { featureName: 'Premium voices' },
    { featureName: 'Customizable embeddable audio player', paywalledFeature: PAYWALLED_FEATURES.EMBED_PLAYER },
    { featureName: 'Customizable sharable audio page' },
    { featureName: 'Podcast distribution', paywalledFeature: PAYWALLED_FEATURES.PODCAST_DISTRIBUTION },
    { featureName: 'Youtube distribution', paywalledFeature: PAYWALLED_FEATURES.YOUTUBE_DISTRIBUTION },
    { featureName: 'Basic analytics', paywalledFeature: PAYWALLED_FEATURES.BASIC_ANALYTICS },
    { featureName: 'Onboarding session (1 hour)' }
];

export const FEATURES_PRO = [
    { featureName: 'Everything in “Basic”' },
    { featureName: '140 credits per month' },
    { featureName: 'Download audio' },
    { featureName: 'Pro analytics', paywalledFeature: PAYWALLED_FEATURES.PRO_ANALYTICS },
    { featureName: 'Workflow integrations' },
    { featureName: 'Lexicon', tooltipText: 'Adjust and save the correct pronunciation of specific words' },
    { featureName: 'Onboarding session (3 hours)' }
];

export const FEATURES_ENTERPRISE = [
    { featureName: 'Everything in “Professional"' },
    { featureName: '140+ credits per month' },
    { featureName: 'Custom voices' },
    { featureName: 'Enterprise support' },
    { featureName: 'Custom analytics' }
];

export const ESTIMATED_CHARS_PER_MINUTE = 1000;
export const CREDITS_PER_MINUTE = 1;

export const CREDITS_PER_CHAR = CREDITS_PER_MINUTE / ESTIMATED_CHARS_PER_MINUTE;

export enum CREDIT_WEIGHT {
    STANDARD = 1,
    PREMIUM_VOICE = 2
}

export const LOWEST_GROUP_VERSION_ON_CREDIT_SYSTEM = 3;
