import axios from 'axios';
import { HostInterface } from '../types/types';
import { formatSentryError } from './telemetryHelpers';
import { captureException } from '@sentry/nextjs';

export const getHostsByGroupId = async (groupId: string, attempt = 0): Promise<HostInterface[]> => {
    try {
        const response = await axios.get(`/api/v2/hosts/get/group/${groupId}`);
        return response.data as HostInterface[];
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API hosts/get/group', 'getHostsByGroupId', err);
        captureException(new Error(message, cause));
        if (attempt < 5) {
            return getHostsByGroupId(groupId, attempt + 1);
        } else {
            throw err;
        }
    }
};

export const postHost = async (payload: HostInterface) => {
    try {
        const response = await axios.post('/api/v2/hosts/post', payload);
        return response.data as string;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API hosts/post', 'postHost', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const deleteHost = async (hostId: string) => {
    try {
        const response = await axios.delete(`/api/v2/hosts/delete/${hostId}`);
        return response.data as string;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API hosts/delete', 'deleteHost', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const patchHost = async (hostId: string, payload: HostInterface) => {
    try {
        const response = await axios.patch(`/api/v2/hosts/patch/${hostId}`, payload);
        return response.data as string;
    } catch (err) {
        const { message, cause } = formatSentryError('Error hitting next API hosts/patch', 'patchHost', err);
        captureException(new Error(message, cause));
        throw err;
    }
};

export const postOrPatchHost = async (payload: HostInterface) => {
    try {
        if (payload.id) {
            return patchHost(payload.id, payload);
        } else {
            return postHost(payload);
        }
    } catch (err) {
        const { message, cause } = formatSentryError('Error posting or patching host', 'postOrPatchHost', err);
        captureException(new Error(message, cause));
        throw err;
    }
};
