import { chakra, Spinner } from '@chakra-ui/react';
import { DarkCard } from '../DarkCard/DarkCard';
import { useAuth } from '../../../context/AuthContext';
import { useFeatureFlagsContext } from '../../../context/hooks/FeatureFlagsHook';
import { useMemo } from 'react';

export const CreditUsageCounter = () => {
    const { group, isLoadingNarrationUsage } = useAuth();
    const { usedAudioStudioNarrationCapacity, availableAudioStudioNarrationCapacity } = useFeatureFlagsContext();

    // * Under new credits system, we show the total purchased and current allocation from the backend values
    // * This logic is handled by FeatureFlagsContext and the values from usedAudioStudioNarrationCapacity and availableAudioStudioNarrationCapacity
    // * reflect the appropriate logic based on group version
    const allocation = useMemo(() => {
        // * Since our system works on estimating credits based off of chars, then charging based off of duration, there is a chance that a user will go over credits, and that is fine.
        // * However, it could be confusing from the user's point of view if they see that they have used more credits than they are allowed, so cap usedCredits at availableCredits
        let usedAmount = usedAudioStudioNarrationCapacity;

        if (usedAmount > availableAudioStudioNarrationCapacity) {
            usedAmount = availableAudioStudioNarrationCapacity;
        }
        return `${usedAmount} / ${availableAudioStudioNarrationCapacity}`;
    }, [usedAudioStudioNarrationCapacity, availableAudioStudioNarrationCapacity]);

    let allocationMessage = useMemo(() => {
        let allocationMessage = ' Credits used this month';

        // * If the user has more than 140 credits then they are on an annual plan
        if (availableAudioStudioNarrationCapacity > 140) {
            allocationMessage = ' Credits used this year';
        }

        // * For old groups, we show the used and available capacity
        if (group && group.version < 3) {
            allocationMessage = ' of episodes used';
        }
        return allocationMessage;
    }, [group]);

    return (
        <DarkCard my={4} pl={2} pr={2} pt={4} pb={4} h="fit-content" gap={3} ml={2} mr={2} data-testid={'audioStudioUsageCounter'}>
            <chakra.span>
                <chakra.span color="brand.500">{isLoadingNarrationUsage ? <Spinner /> : allocation}</chakra.span>
                {allocationMessage}
            </chakra.span>
        </DarkCard>
    );
};
