import axios from 'axios';
import { CoreWorkspaceDocumentsInterface, FirestoreDocumentIdType } from '../types/types';
import { captureException } from '@sentry/nextjs';
import { formatSentryError } from './telemetryHelpers';
import { delayAsyncCode } from './timeHelpers';

export const getSubscription = async (userId: FirestoreDocumentIdType) => {
    try {
        const response = await axios.get(`/api/v2/users/getSubscription/${userId}`);
        return response.data;
    } catch (error) {
        const { message, cause } = formatSentryError('Error getting stripe customer', 'accountHelpers', error);
        captureException(new Error(message, cause));
        return null;
    }
};

const MAX_RETRIES = 5;
export const getOrCreateCoreWorkspaceDocuments = async (uid: FirestoreDocumentIdType, attemptCount = 1): Promise<CoreWorkspaceDocumentsInterface> => {
    try {
        const response = await axios.post(`/api/v2/core-workspace-documents/post`, { uid });
        return response.data as CoreWorkspaceDocumentsInterface;
    } catch (error) {
        // * retry 5 times
        if (attemptCount > MAX_RETRIES) {
            const { message, cause } = formatSentryError(
                `Failed to create or retrieve core workspace documents after ${MAX_RETRIES} retries`,
                'accountHelpers',
                error
            );
            captureException(new Error(message, cause));
            throw error;
        } else {
            const { message, cause } = formatSentryError(
                `Failed to create or retrieve core workspace documents after ${attemptCount} retries. Max retries are set at ${MAX_RETRIES}. Trying again...`,
                'accountHelpers',
                error
            );
            captureException(new Error(message, cause));

            // * recursively re-call the function in case group hasn't been created yet, with an increasing delay
            await delayAsyncCode(1000 * attemptCount);
            return getOrCreateCoreWorkspaceDocuments(uid, attemptCount + 1);
        }
    }
};
